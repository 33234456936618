button {
  position: relative;

  &.loading {
    color: transparent !important;
    pointer-events: none !important;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border: 3px solid var(--mdc-protected-button-label-text-color);
      border-top: 3px solid transparent;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin .5s linear infinite;
    }
  }

  &.mat-mdc-outlined-button {
    &.loading {
      &::after {
        border: 3px solid var(--mdc-outlined-button-label-text-color);
        border-top: 3px solid transparent;
      }
    }
  }
  &.mat-mdc-unelevated-button {
    &.loading {
      &::after {
        border: 3px solid var(--mdc-filled-button-label-text-color);
        border-top: 3px solid transparent;
      }
    }
  }
  &.mat-mdc-icon-button {
    &.loading {
      &::after {
        border: 3px solid var(--color-primary);
        border-top: 3px solid transparent;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
