.fs-xs {
  @apply text-xs;
}

.fs-sm {
  @apply text-sm;
}

.fs-base {
  @apply text-base;
}

.fs-lg {
  @apply text-lg;
}

.fs-xl {
  @apply text-xl;
}

.fs-2xl {
  @apply text-2xl;
}

.fs-3xl {
  @apply text-3xl;
}

.fs-4xl {
  @apply text-4xl;
}

.fs-5xl {
  font-size: 42px;
}
