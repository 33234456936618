:root {
  --mdc-switch-track-width: 32px;
  --mdc-switch-track-height: 16px;
  --mdc-switch-handle-height: 13px;
  --mdc-switch-handle-width: 13px;
}

mat-slide-toggle {

  .mdc-switch--selected,
  .mdc-switch--checked {
    .mdc-switch__track {
      opacity: 0.2;
    }
  }
  .mdc-switch__icons {
    display: none;
  }
}
