.mat-mdc-menu-content,
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  min-width: 160px;
}

.mat-mdc-menu-item,
.mat-mdc-option {
  font-size: 14px !important;
  min-height: 40px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  color: var(--color-dark) !important;
}

.mdc-list-item__primary-text {
  font-size: 14px !important;
}
