mat-icon {
  &.size-sm {
    width: 1rem;
    height: 1rem;
  }

  &.size-smd {
    width: 1.25rem;
    height: 1.25rem;
  }

  &.size-md {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.size-lmd {
    width: 3.5rem;
    height: 3.5rem;
  }

  &.size-xl {
    width: 10rem;
    height: 10rem;
  }
}
