.mdc-notched-outline__notch {
  border-right: none !important;
}

.mat-mdc-option {
  input,
  textarea,
  span {
    line-height: 1.43;
    font-size: 14px;
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 16px !important;
  font-size: 14px !important;
  font-family: "Helvetica Neue", sans-serif;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-22.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
  transform: var(--mat-mdc-form-field-label-transform) !important;
}

.mat-mdc-form-field, .mat-mdc-select, .mat-mdc-option {
  font-size: 14px !important;
  line-height: 23px;

  .mat-mdc-icon-button {
    width: 38px;
    height: 38px;
    padding: 6px;
  }

  mat-icon {
    width: 16px;
    height: 16px;
    padding: 10px !important;
  }

  .mdc-notched-outline {
    &__leading {
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important;
    }

    &__trailing {
      border-top-right-radius: 2px !important;
      border-bottom-right-radius: 2px !important;
    }
  }

}

.mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  padding: 0.5em 0 !important;
  min-height: 38px;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 24px;
}

.mat-mdc-text-field-wrapper {
  background: white;
}

label {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  &.required::after {
    margin-left: 1px;
    margin-right: 0;
    content: "*";
    color: var(--color-danger);
  }
}

//.mat-mdc-form-field {
//
//  .mat-mdc-form-field-suffix {
//    color: #757575;
//  }
//}

.expiration-hint {
  margin-top: -1.5rem;
  color: #0009;
  font-size: 1.2rem;
  top: calc(100% - 1.7916666667em);
  display: block !important;
  font-weight: 400;
  line-height: 1.4rem;
  font-family: Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin-bottom: 1.6rem;
  padding: 0 1.2rem;
}

.form-group {
  mat-form-field {
    mat-label {
      font-size: 16px !important;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 1.2rem;
      background-color: transparent !important;

      .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
          top: 2rem !important;
        }
      }

      .mat-mdc-form-field-infix {
        padding-top: 3.2rem !important;

        .mdc-text-field__input {
          color: #000000;
        }
      }
    }

    &:hover {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
      }
    }

    &.mat-focused {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
      }
    }

    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
      align-self: end;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    line-height: 1.4rem;

    .mat-mdc-form-field-error-wrapper {
      padding: 0 1.2rem;
    }

    .mat-mdc-form-field-bottom-align:before {
      display: block;
      height: .5rem;
    }
  }

  .mdc-menu-surface {
    padding: 0 !important;
  }
}

.form-group .mat-mdc-text-field-wrapper.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: #F4F5F8 !important;
  color: #808289 !important;
}
