/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './theme';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import '@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
  height: 100%;
}

body {
  @apply m-0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
}

