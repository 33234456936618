.mat-mdc-select {
  .mat-mdc-select-value {
    font-size: 14px;
  }
  &.no-arrow {
    .mat-mdc-select-arrow-wrapper {
      display: none !important;
    }
  }
}
