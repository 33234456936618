.card {
  @apply bg-white rounded-sm shadow-sm h-full;
  h2 {
    @apply text-2xl leading-8 font-medium;
  }
  h4 {
    @apply text-xl leading-8 font-medium;
  }

  .label {
    @apply text-sm leading-5;
  }

  .divider {
    background-color: var(--color-border);

    &.vertical {
      @apply h-auto w-px;
    }

    &.horizontal {
      @apply w-full h-px;
    }
  }
}

.status-label {
  @apply inline-flex items-center h-6 px-1.5 text-xs font-normal;
  &.success {
    color: var(--color-success);
    background-color: rgba(var(--color-success-rgb), 0.1);
  }

  &.pending {
    color: var(--color-warning);
    background-color: rgba(var(--color-warning-rgb), 0.1);
  }

  &.failed {
    color: var(--color-danger);
    background-color: rgba(var(--color-danger-rgb), 0.1);
  }
}
