.hea {
  .md-drppicker {
    min-width: 600px;

    .btn {
      background-color: var(--color-secondary);

      &:hover {
        background-color: var(--color-secondary);
      }

      &:disabled {
        background-color: var(--color-dark);
      }
    }

    .ranges ul li button.active {
      background-color: var(--color-secondary);
    }

    td.active, td.active:hover {
      background-color: var(--color-secondary);
    }
  }
}
