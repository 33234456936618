:root {
  --tab-bar-height: 64px;

  /** primary **/
  --color-primary: #02aff3;
  --color-primary-rgb: 2, 175, 243;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 0, 0, 0;
  --color-primary-shade: #029ad6;
  --color-primary-tint: #a4e5ff;

  /** secondary **/
  --color-secondary: #02aff3;
  --color-secondary-rgb: 2, 175, 243;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 0, 0, 0;
  --color-secondary-shade: #029ad6;
  --color-secondary-tint: #a4e5ff;

  /** tertiary **/
  --color-tertiary: #074ee8;
  --color-tertiary-rgb: 82, 100, 255;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255, 255, 255;
  --color-tertiary-shade: #4858e0;
  --color-tertiary-tint: #6374ff;

  /** success **/
  --color-success: #2dd36f;
  --color-success-rgb: 45, 211, 111;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;

  /** warning **/
  --color-warning: #fdc300;
  --color-warning-rgb: 253, 195, 0;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0, 0, 0;
  --color-warning-shade: #dfac00;
  --color-warning-tint: #fdc91a;

  /** danger **/
  --color-danger: #e74459;
  --color-danger-rgb: 231, 68, 90;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #cb3c4f;
  --color-danger-tint: #e9576b;

  /** dark **/
  --color-dark: #222428;
  --color-dark-rgb: 34, 36, 40;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255, 255, 255;
  --color-dark-shade: #1e2023;
  --color-dark-tint: #383a3e;

  /** medium **/
  --color-medium: #808289;
  --color-medium-rgb: 128, 130, 137;
  --color-medium-contrast: #000000;
  --color-medium-contrast-rgb: 0, 0, 0;
  --color-medium-shade: #717279;
  --color-medium-tint: #8d8f95;

  /** light **/
  --color-light: #f4f5f8;
  --color-light-rgb: 244, 245, 248;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0, 0, 0;
  --color-light-shade: #d7d8da;
  --color-light-tint: #f5f6f9;

  /** border **/
  --color-border: #d7d8da;

  //--mdc-icon-button-icon-size: 20px !important;
}
