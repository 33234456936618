@font-face {
  font-family: 'Poppins';
  src: url(./Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url(./Poppins-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url(./Poppins-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
