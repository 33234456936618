.items-list {
  margin-top: 30px;

  .items-row {
    height: 40px;
    display: table-row;

    .label {
      color: var(--color-medium);
      font-size: 16px;
      line-height: 24px;
      display: table-cell;
      padding-right: 25px;
    }

    .value {
      color: #000;
      font-size: 16px;
      line-height: 24px;
      display: table-cell;
    }
  }
}