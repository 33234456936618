:root {
  --mat-paginator-height: 36px;
}

mat-paginator {
  .mat-mdc-paginator-container {
    min-height: 36px;
    padding: 0;
  }

  .mat-mdc-paginator-page-size {
    .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
      padding: 0 !important;
      min-height: 20px;
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border: none;
    //border-color: var(--color-medium-shade) !important;
  }

  .mdc-notched-outline__leading {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .mdc-notched-outline__trailing {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
}
