@use "ag-grid-community/styles" as ag;

@include ag.grid-styles((
  theme: hea-grid,
  extend-theme: alpine,
  odd-row-background-color: transparent,
  row-border-color: transparent,
  row-hover-color: var(--color-light),
  header-height: 32px,
));

.ag-grid-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .ag-grid-loader {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: 4px;
  }
}

.ag-theme-hea-grid {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  overflow: hidden;
  --ag-header-foreground-color: var(--color-medium-shade);
  --ag-header-background-color: white;
  --ag-borders: none;
  --ag-font-family: Outfit, "Helvetica Neue", sans-serif;

  .ag-cell-value {
    display: flex;
    align-items: center;
  }

  &.with-pagination {
    height: calc(100% - var(--mat-paginator-height));
  }

  .ag-header-row {
    font-weight: 500;
  }

  .ag-header-cell {
    font-size: 12px;
    border-bottom: 1px solid var(--color-medium-tint);
  }
}
