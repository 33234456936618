.card .mat-expansion-panel-body {
  padding: 0 !important;
}


.mat-expansion-indicator {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: var(--color-light);
  border-radius: 50%;

  &::after {
    border-color: var(--color-medium);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 10px;
    height: 10px;
  }
}

.mat-expanded {
  .mat-expansion-indicator {
    background-color: var(--color-primary);

    &::after {
      border-color: white;
    }
  }
}