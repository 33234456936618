.success-message {
  background: rgba(86, 189, 102, 0.22);
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #222428;
}

.validation-message {
  background: rgb(235, 68, 90, 0.22);
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #cf3c4f;
}

.warning-message {
  background: rgba(253, 195, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(51, 51, 51, 1);

  display: flex;
  align-items: center;

  mat-icon {
    font-size: 14px;
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
}
