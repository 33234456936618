.currency-prefix {
  position: relative;

  .prefix {
    position: absolute;
    color: #000;
  }

  input {
    padding-left: 10px;
  }
}
