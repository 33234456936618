.payment-management, .receipt {
  h2 {
    @apply text-2xl leading-8 font-medium;
  }

  h4 {
    @apply text-xl leading-8 font-medium;
  }

  h5 {
    @apply text-sm leading-8 font-bold;
  }

  .payment-border-block {
    border: 1px solid #D7D8DA;
    @apply p-6 relative;
  }

  .mat-mdc-outlined-button:not(:disabled) {
    border-color: var(--mdc-outlined-button-label-text-color, inherit) !important;
  }

  .after-payment-signup {
    background: rgba(245, 246, 249, 1);
    padding: 10px 20px;
    color: rgba(34, 36, 40, 1);
    @apply text-sm;
    display: flex;

    mat-icon {
      margin-right: 15px;
    }


    a {
      color: rgba(2, 175, 243, 1);
      text-decoration: none !important;
      border-bottom: 1px solid transparent;
      transition: border 200ms;

      &:hover {
        border-bottom: 1px solid rgba(2, 175, 243, 1);
      }
    }

  }

  .terms {
    display: flex;
    align-items: center;

    li {
      background: rgba(2, 175, 243, 0.1);
      @apply text-xs font-semibold leading-none;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      height: 32px;
      line-height: 32px;

      &:last-child {
        margin-right: 0;
      }


      .mat-icon {
        padding-left: 10px;
        color: rgba(128, 130, 137, 1);
        cursor: pointer;
        width: 30px;
      }
    }
  }

  .payment-details {
    .label, .value {
      @apply text-base font-light leading-6;
      padding: 8px 0;
    }

    .label {
      color: rgba(51, 51, 51, 1);
      padding-right: 15px;
      max-width: 150px;
      width: max-content;
    }

    .value {
      padding-left: 15px;
      color: rgba(0, 0, 0, 1);
    }

    table {
      width: 100%;
    }

    p {
      @apply text-sm text-left;
      padding: 20px 0 0;
    }

    .terms {
      li {
        @apply font-normal;
      }
    }
  }

  .payment-status-header {
    @apply text-center;
    h2, mat-icon {
      margin-top: 15px;
    }

    .icon {
      width: 30px;
      height: 35px;
    }

    .header-p {
      padding: 20px 0 16px;
      @apply text-sm text-left;
      p {
        @apply text-sm text-left mb-2;
        a {
          color: var(--color-primary);
          text-decoration: none !important;
          transition: border 200ms;
          border-bottom: 1px solid var(--color-primary);

          &:hover {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }

  .max-w-816 {
    max-width: 816px;
    margin-left: auto;
    margin-right: auto;
  }


  .max-w-595 {
    max-width: 595px;
    margin-left: auto;
    margin-right: auto;
  }

}
